/* Miscellaneous Mixins */

// general transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	  -o-transform: $transforms;
	 -ms-transform: $transforms;
 -webkit-transform: $transforms;
	   transform: $transforms;
}

// rotate
@mixin rotate ($deg) {
@include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}
// translate
@mixin translate ($x, $y) {
@include transform(translate($x, $y));
}
// skew
@mixin skew ($x, $y) {
@include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin ($origin) {
 moz-transform-origin: $origin;
	  -o-transform-origin: $origin;
	 -ms-transform-origin: $origin;
 -webkit-transform-origin: $origin;
	   transform-origin: $origin;
}
//Ellipsis
%ellipsor{
	text-overflow: ellipsis;
	overflow: hidden;
	max-width:100%;
	white-space: nowrap;
}

@mixin ellipsor {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

// Placeholder
@mixin placeholder {
 &::-webkit-input-placeholder {
	 @content
 }
 &:-moz-placeholder {
	 @content
 }
 &::-moz-placeholder {
	 @content
 }
 &:-ms-input-placeholder {
	 @content
 }
}

@mixin hexagon-variations($color) {
	background: $color;
	color: $white;
	&:after {
	  border: 26px solid transparent;
	  border-top: 13px solid $color;
	}
	&:before {
	  border: 26px solid transparent;
	  border-bottom: 13px solid $color;
	}
  }
  